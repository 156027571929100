/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
// @media print styles are included to improve layout and content when printing in the web application.

@media print {
    ion-content {
      max-width: none;
    }
    ion-header, .tabbar {
      display: none !important;
    }
  
    body, .app-root, .split-pane, .ion-page, ion-app, ion-nav {
      contain: none;
    }
    body, ion-nav {
      overflow: initial;
    }
    body {
      position: static;
    }
  
    .scroll-content, .ion-page.show-page, .ion-page.show-page > ion-content, .app-root, ion-tabs, ion-tab {
      position: relative;
      height: auto;
      contain: none;
      overflow: visible;
    }
  
    .fixed-content, .scroll-content {
      margin-top: 0 !important;
    }
  }

.newwaybillcss{
    .modal-wrapper{
        width:100%;
        max-width:1000px;
        border-radius:10px;
        background-color:white;
    }
}


.select_sender_css{
    
    .modal-wrapper{
        border-radius:10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }
}

.viewdetailscss{
    .modal-wrapper{
        --background:white;
        background-color:white;
        border-radius:10px;
    }
}

.viewstatuscss{
    .modal-wrapper{
        --background:white;
        background-color:white;
        border-radius:10px;
    }
}
